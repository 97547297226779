import { Box, Container, Heading } from '@chakra-ui/react';
import { ButtonFullWidthMobile } from '@components/Button';
import { StarBackground } from '@components/StarBackground';
import { fullSizeParentStyles, LANDING_ROUTES, pxToRem } from '@cyberkongz/ui';
import { PageSEOTags } from '@features/seo/SEO';

export default function Custom404Page() {
  return (
    <StarBackground
      stylesContainer={{
        w: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&:after': {
          ...fullSizeParentStyles,
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(86.18% 100% at 50% 100%, #017C8F 0%, #000000 100%)',
          display: 'block',
          content: '""',
          opacity: 0.4,
        },
      }}
    >
      <PageSEOTags pageKey="404" />
      <Box width="100%" pt={40} pb={20} position="relative" zIndex={2}>
        <Container>
          <Box textAlign="center">
            <Heading css={{ fontSize: [pxToRem(128), pxToRem(208)], lineHeight: 0.5 }}>404</Heading>
            <Heading variant="displayPrimary" css={{ fontSize: ['7xl', '10xl'], mb: 8 }}>
              not found
            </Heading>
            <ButtonFullWidthMobile variant="displayOutline" href={LANDING_ROUTES.HOME}>
              back to home
            </ButtonFullWidthMobile>
          </Box>
        </Container>
      </Box>
    </StarBackground>
  );
}
